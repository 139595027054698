// 注文のキャンセル

import api from '../api'

const path = '/orders/{code}/cancel'

export default code => {
  return api({
    method: 'post',
    url: path.replace('{code}', code),
    auth: true
  })
}
