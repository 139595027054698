// 注文履歴ページ

<template lang="pug">
.my-orders

  section
    h2.text-3xl.font-bold.text-teal-700.lg__text-center ご注文履歴

    p.mt-10(v-if='!orders') 読み込み中です...

    p.mt-10(v-else-if='orders.length === 0') 購入履歴はありません。

    ul.orders.mt-5.lg__mt-10.mx-auto

      li.border.border-blue-400.bg-white.p-3.mt-5(
        v-for='item in orders'
        :class='`status-${item.status.code}`'
      )
        .sm__flex
          .left.flex-1
            .flex
              .w-24.text-right.text-blue-600 注文番号:
              .ml-3 {{ item.code }}
            .flex
              .w-24.text-right.text-blue-600 注文状況:
              .ml-3
                span.font-bold {{ item.status.name }}
                a.ml-3.underline.text-blue-700.cursor-pointer(
                  v-if='item.payment.isCancelable',
                  @click='clickOrderCancel(item)'
                ) (キャンセルする)
            .flex
              .w-24.text-right.text-blue-600 注文日時:
              .ml-3 {{ item.createdAt | yyyyMMddHHmm }}
          .right.flex-1
            .flex
              .w-24.text-right.text-blue-600 合計金額:
              .ml-3 {{ item.detail.payment.total }} 円
            .flex
              .w-24.text-right.text-blue-600 点数:
              .ml-3 {{ item.detail.payment.totalQuantity }} 点
            .flex
              .w-24.text-right.text-blue-600 支払方法:
              .ml-3 {{ item.payment.name }}
        .flex.border-t.border-grey-400.pt-2.mt-2
          .w-24.text-right.text-blue-600 ご注文者:
          .ml-3
            | 〒{{ item.detail.customer.zipcode }}
            br
            | {{ item.detail.customer.prefecture }}
            | {{ item.detail.customer.city }}{{ item.detail.customer.address2 }}{{ item.detail.customer.address3 }}
        .flex
          .w-24.text-right.text-blue-600 お届け先:
          .ml-3(v-if='item.detail.destination')
            | 〒{{ item.detail.destination.zipcode }}
            br
            | {{ item.detail.destination.prefecture }}
            | {{ item.detail.destination.city }}{{ item.detail.destination.address2 }}{{ item.detail.destination.address3 }}
            br
            | {{ item.detail.destination.name }} 様
          .ml-3(v-else) ご注文者と同じ

        //- コンビニかつ入金前の場合支払い情報を表示
        .mt-3.bg-yellow-200.p-2.rounded(v-if='item.payment.code === "conveni" && item.status.code === "created"')
          h3.text-center.font-bold.text-sm コンビニでのお支払いについて
          .sm__flex.mt-2
            .left.flex-1
              .flex
                .w-24.text-right.text-gray-700 お支払先:
                .ml-3 {{ item.payment.conveni.name }}
              .flex
                .w-24.text-right.text-gray-700 {{ item.conveniNativeInfo.numberName }}:
                .ml-3 {{ item.payment.conveni.conveniCode }}
            .right.flex-1
              .flex
                .w-24.text-right.text-gray-700 お支払期限:
                .ml-3.font-bold.text-red-600 {{ item.payment.conveni.expiresJp }}
              .flex(v-if='item.payment.conveni.slipUrl')
                .w-24.text-right.text-gray-700 払込票:
                .ml-3
                  | 印刷をご希望の場合は
                  a.underline.text-blue-700(
                    :href='item.payment.conveni.slipUrl', target='_blank', rel='noopener'
                  ) こちら
              .flex(v-if='item.payment.conveni.companyCode')
                .w-24.text-right.text-gray-700 企業コード:
                .ml-3 {{ item.payment.conveni.companyCode }}
          .flex
            .w-24.text-right.text-gray-700 備考:
            .ml-3 {{ item.conveniNativeInfo.description }}
</template>

<script>
import { mapActions } from 'vuex'

import getOrderItemsApi from '../../api/orders/get-items'
import cancelOrderApi from '../../api/orders/cancel'
import conveniInfo from '../../utils/conveni-info'
import errorCodes from '../../errors/codes'
import ConfirmMessageModal from '../../modals/confirm-message'

export default {
  name: 'MyOrders',

  data () {
    return {
      orders: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    }),
    async init () {
      // 注文履歴を取得
      const response = await getOrderItemsApi()
      if (!response.ok) {
        this.setError({ code: errorCodes.GET_ORDER_ITEMS_ERROR })
        this.orders = []
        return
      }
      this.orders = response.payload.items.map(item => {
        if (item.payment.code === 'card') return item
        return {
          ...item,
          conveniNativeInfo: conveniInfo[item.payment.conveni.code]
        }
      })
    },
    async clickOrderCancel (item) {
      const result = await this.$showModal(
        ConfirmMessageModal,
        true,
        {
          title: '確認',
          message: `「注文番号:${item.code}」をキャンセルしてもよろしいですか？`
        }
      )
      if (!result.ok) return
      // 実際にキャンセル処理
      const response = await cancelOrderApi(item.code)
      if (!response.ok) {
        this.setError({ code: errorCodes.CANCEL_ORDER_ERROR })
        return
      }
      // 成功したら状態を更新
      await this.init()
    }
  }
}
</script>

<style lang="sass" scoped>
.my-orders
  section
    .orders
      max-width: 800px
      li.status-canceled
        background: #eee
</style>
