// 注文履歴の取得

import api from '../api'

const path = '/orders'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
